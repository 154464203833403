.content .contact-list {
	list-style-type:none;
	max-width: 1000px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0;
	li {
		margin-top: 25px;
	}
}
.contact-word{
	font-size: 16px;
	width: 30%;
	display: inline-block;
	text-align: right;
	padding-right: 10px;
	/* font-family: futura-pt, itc-avant-garde-gothic-pro, rubik, Sans-serif; */
	font-family: $title-font;
}
@include media-breakpoint-up(sm){
	.contact-word{
		font-size: 28px;
		width: 50%;
	}
	.contact-number{
		width: 45%;
		font-size: 22px;
	}
}
.contact-number{
	padding-left: 10px;
	display: inline-block;
	font-size: 18px;

	a{
		color: $orange;
	}
	a:visited {
	  	color: $orange;
	}

	a:hover,
	a:focus,
	a:active {
	  	color: $lavender;
	}
}
.morse {
	width: 200px;
	height: 10px;
	margin-right: auto;
	margin-left: auto;
	/*background-image: url('/assets/img/art/morse.svg');
	background-size: fill; */
}