
.content.container{
	max-width: 740px;
	margin-top: 30px;
}
.projects.container {
	max-width: 1100px;
	display: flex;
	p {
		flex: 100%;
	}
	align-items: center;
}
.all-projects {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	max-width: 100%;
}
.tall {
	height:100%;
	width:100%;
}
.sticky {
	position: sticky;
	top: 10px;
}

.tech-category{
	background-size: cover;
    height: 40px;
    width: 40px;
	display: inline-block;
	cursor: pointer;
	&.disabled-true {
		opacity: 0.2;
		pointer-events: none;
	}
}
.tech-category.Hardware{
	background-image: url("/assets/img/icon/hardware.png");

}
.tech-category.Communications{
	background-image: url("/assets/img/icon/communications.png");
}
.tech-category.Mobile{
	background-image: url("/assets/img/icon/mobile.png");
}
.tech-category.Backend{
	background-image: url("/assets/img/icon/backend.png");
}
.tech-category.Data-science{
	background-image: url("/assets/img/icon/datascience.png");
}
.button-container{
	display: flex;
}
.learn-more.send{
	display: inline-block;
	float:left;
	margin-bottom: 24px;
}
.actuate-container {
	font-size: 14px;
	margin-top: 16px;
	margin-bottom: 16px;
	a:link {
		color:$orange;
	}
	a:visited{
		color:$orange;
	}
}
.quote-container {
	margin-top: 16px;
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 16px;
	padding-bottom: 3px;
	border-radius: 10px;
	z-index: -2;
	background-color: #e5e2e8;
}
.quote {
	font-size: 18px !important;
	color: #482922 !important;
}
.quote-container-bottom {
	width: 0;
	height: 0;
	border-style: solid;
	margin-left: 30px;
	border-width: 40px 3px 0 30px;
	border-color: #e5e2e8 transparent transparent transparent;
	display: inline-block;
}
.author {
	margin-top: -24px;
	text-align: right;
	padding-top: 0;
	padding-left: 0;
	padding-bottom: 0;
	padding-right: 8px;
}
img.author-image.quote{
	display: inline-block;
	border-radius: 50%;
	width: 40px;
	height: 40px;
}
.short-description {
	font-size: 14px;
	line-height: 22px;
	/* font-family: Lato, sans-serif; */ /* unnecessary, right? */
	height: 45px;
	overflow: hidden;
	position: relative;
}
.short-description:after {
	content:"";
	text-align: right;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 70%;
	height: 22px;
	background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}
.tag-list {
	flex-wrap: wrap;
	margin-left: -15px;
	margin-bottom: 15px;
}
.tag-item {
    font-size: 12px;
    color: #333;
    border: 2px solid #969696;
    border-radius: 5px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 15px;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    padding: 0;
    min-width: calc(25% - 15px);
	&.disabled-true {
		opacity: 0.2;
		pointer-events: none;
	}
}
.tag-item:not(.disabled-true):hover {
	background-color: $gray-300;
}

.projects.collapsed {
    margin-top: 50px;
}

.collapsed {
	.tag-list {
		white-space: nowrap;
	}
	.technologies-break {
		border-top-color: $peach;
	}
	.technologies-container {
		margin-left: -12px;
		display: block;
	}
	.tech-category {
		margin-left: 8px;
		vertical-align: bottom;
	}

	@include media-breakpoint-up(sm) {
		.technologies-break {
			width: 100%;
			margin-left: 0;
		}
	}
	.client-box {
		margin-top: -3px;
		margin-bottom: 3px;
	}
	.client-text {
		color: $peach;
		font-size: 12px;
		display: block;
	}
	.client-text::before{
		content: "Built for ";
		color: $peach;
		font-size: 12px;
	}
	.client-logo-container {
		overflow: hidden;
		height: 200px;
		background-color: #f0f5f9;
		padding: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.client-logo {
		margin-bottom: 0;
		max-width: 100%;
		max-height: 100%;
		display: block;
	}
	.youtube-player {
		height: 100%;
	}

	.project {
		flex: none;
		width: 335px;
		padding-top: 20px;
		padding-bottom: 14px;
		margin-bottom: 45px;
		opacity: 1;
		color: black;
	}
	.project.hide{
		opacity: 0;
	}
	.project-header {
		/* font-family: "Lato"; */ /* unnecessary and incomplete, right? */
		font-size: 19px;
		line-height: 26px;
		font-weight: bold;
		color: black;
		margin-bottom: 14px;
		display: inline-block;
		color: black;
		margin-top: 15px;
	}
	.short-description {
		font-size: 16px;
	}
	.project-info-container {
		padding-right: 0;
	}
	.project-thumb {
		float: right;
		clear: both;
		max-height: 56px;
		max-width: 140px;
		margin-bottom: 18px;
		display: none;
	}
}
.expanded {
	.pd-quote-container{
		max-width: 650px;
		text-align: center;
		margin-top: 30px;
	    margin-bottom: 30px;
		margin-left: auto;
		margin-right: auto;
	}
	.qt-author-container{
		text-align: center;
		margin-top: 15px;
		margin-right: 25px;
		color: #482922;

	}
	.qt-image{
		width: 43px;
		border-radius: 25px;
		margin-bottom: 0px;
		display: none;
	}
	.qt-author{
		padding-bottom:0px;
		padding-left: 5px;
		font-size: 19px;
		text-align: center;
    	font-size: 14px;
	}
	.project-title-container {
		padding: 20px;

		@include media-breakpoint-up(lg) {
			padding: 5px;
		}
	}
	.title-container{
		padding-top: 40px;
	}
	.tag-list{
		margin-top: -8px;
		margin-bottom: -8px;
		max-width: 400px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		margin-top: 40px;
	}
	.client-logo-container {
		width: 100%;
		height: 125px;
		background-color: #f0f5f9;
		padding: 36px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			object-fit: contain;
		}

		@include media-breakpoint-down(sm) {
			height: auto;
		}
	}
	.column-title {
		width: 70%;

		@include media-breakpoint-down(sm) {
			order: 2;
			margin-top: 25px;
			width: 100%;
		}
	}
	.column-image {
		width: 30%;
		padding-left: 40px;

		img {
			max-width: 200px;
		}

		@include media-breakpoint-down(sm) {
			order: 1;
			width: 100%;
			padding-left: 0;
		}
	}
	.client-logo {
		margin-bottom: 0;	
		max-width: 100%;
		min-height: 100%;
		max-height: 100%;
		display: block;
	}
	.project {
		margin-top: 100px;
		width: 100%;
		align-items: center;
		border-bottom: 2px solid $peach;
		padding-bottom: 30px;
	}
	.project-header {
		font-size: 30px;
		font-weight: bold;
	}
	.client-desc {
		font-size: 19px;
		align-self: center;
		@include media-breakpoint-down(md) {
			font-size: 16px;
		}
	}
	.project-detail-row.project-quote-container {
		background-color: rgba(200,200,200,0.1);
		margin-bottom: 30px;
		margin-top: 30px;
	}
	.project-image-inner {
		padding: 0;
		min-height: 365px;
		iframe,
		img,
		picture {
			object-fit: contain;
		}
	}
	//manage the rest
	.project-image {
		max-width: 100%;
		display: block;
		margin-left: auto;
		clear: both;
	}
	.client-box {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.project-info-container {
		padding-left: 20px;
		padding-right: 20px;
		padding-right: 8px;
		@include media-breakpoint-up(lg) {
			padding: 5px;
		}
	}
	.star-header {
		font-size: 19px;
		width: 95px;
		display: inline-block;
		vertical-align: middle;
		font-weight: 600;
		@include media-breakpoint-down(md) {
			font-size: 16px;
		}
	}
	p {
		margin-bottom: 8px;
	}
	.star-text {
		font-size: 19px;
		display: inline-block;
		margin-bottom: 20px;
		@include media-breakpoint-down(md) {
			font-size: 16px;
		}
	}
	.star-container {
		padding-top: 0;
		padding-bottom: 16px;
		padding-left: 0;
		padding-right: 50px;
	}
	.technologies-container{
		margin-top: 40px;
		margin-bottom: 20px;
		display: flex;
		justify-content: center;
	}
	.tech-category-container {
		display: flex;
		flex-flow: column;
		align-items: center;
		width: calc(100% / 5);
	}
	.tech-category-text{
		vertical-align: top;
		margin-left: 0;
		margin-top: 2px;
		text-align: center;
		&.disabled-true {
			opacity: 0.2;
			pointer-events: none;
		}
	}
	.product-image-container {
		padding-right: 20px;
		min-height: 220px;
		order: -1;

		@include media-breakpoint-up(lg) {
			padding: 5px;
		}

		padding-bottom: 40px;

		img {
			display: block;
			margin: auto;
			max-height: 100%;
			max-width: 100%;
			position: absolute;
			width: auto;
			height: auto;
			top: 0;
			bottom: 0;
			left: unset;
			right: 0;

			@include media-breakpoint-down(sm) {
				left: 0;
			}
		}

		@include media-breakpoint-up(md) {
			order: 0;
		}
	}
	ul {
		list-style-image: url("/assets/img/icon/prompt.svg");
//		list-style: none;
		padding-inline-start: 50px;
		li {
			margin-top: 3px;
		}
	}
	//handle responsive issues
	@include media-breakpoint-up(md) {
		.technologies-container{
			padding-left: 0;
		}
	}
}
// handle filters
.filter-container {
	margin-top: 72px;
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
.filter-descriptor {
	display: none;
}
.filter-number {
	display: none;
}
.filter-list-category{
	.filter-item {
		display: inline-block;
		border: none;
		/* font-family: futura, sans-serif; */ /* should be futura-pt, but rather... */
		font-family: $title-font;
	}
	.filter-item:hover {
		color: $brown;
		background: initial;
	}
	.filter-item:active {
		text-decoration: underline;
		border:none;
	}
	.filter-item.exclude{
		background-color: initial;
		color: $orange;
	}
	.filter-item.exclude:hover{
		background-color: initial;
		color: $brown;
	}
	.all-on {
		color: $orange;
	}
}
.filter-list-tag {
	max-width: 800px;
    margin: 0 auto;
	max-width: 600px;
}
.filter-item.exclude{
	background-color: $gray-300;
	color: black;
}
.filter-show{
	background-color: $gray-300;
	border-radius: 5px;
	color: black;	
}
.filter-item{
	display: inline-block;
	margin-right: 7px;
	cursor: pointer;
	margin-bottom: 7px;

	padding: 5px 10px;
    color: #333;
    border: 2px solid #969696;
    border-radius: 5px;
	font-size: 14px;
}
.filter-item:hover{
	background-color: $gray-400;
	color: black;
}
.filter-item:active{
	background-color: $gray-700;
}
.filter-on{
	.Hardware-card{
		display: none;
	}
	.Communications-card{
		display: none;
	}
	.Mobile-card{
		display: none;
	}
	.Backend-card{
		display: none;
	}
	.Data-science-card{
		display: none;
	}
}
#filter-explanation{
	padding-left: 40px;
}
.filter-number{
	display: inline-block;
	padding: 2px;
	margin-left: 5px;
	font-size: 10px;
	border-radius: 50%;
}

.project.with-quote {
	
	.actuate img {
		margin-left: 5px;
	}

	:hover {
		.project-info-quote {
			opacity: 1;
			transition: 0.35s ease-in-out;
			position: unset;
			visibility: visible;
	
			position: absolute;
			top: 15px;
			left: -2px;
			right: -2px;
			bottom: 0;
			background-color: white;
		}
	}

	.project-info-container {
		position: relative;
	}

	.project-info-quote {
		opacity: 0;
		position: absolute;
		visibility: hidden;

		a {
			text-decoration: none;
		}

		p {
			font-size: 14px;
		}

		img {
			max-width: 35px;
			border-radius: 50%;
			margin-left: 5px;
		}

		.quote-cite {
			font-size: 15px;
			text-align: right;

			cite {
				font-size: 10px;
				font-style: normal;
			}
		}	
	}
}
.project-info-quote-container {

	a {
		text-decoration: none;
	}

	p {
		font-size: 16px;
	}

	img {
		max-width: 35px;
		border-radius: 50%;
		margin-left: 5px;
	}

	.quote-cite {
		font-size: 15px;
		text-align: right;

		cite {
			font-size: 13px;
			font-style: normal;
		}
	}	
}
.tech-category-text  {
	font-size: 14px !important;

	@media screen and (min-width: 767px) and (max-width: 990px) {
		font-size: 10px !important;
	}

	@media screen and (max-width: 512px) {
		font-size: 12px !important;
	}

	@media screen and (max-width: 440px) {
		font-size: 10px !important;
	}

	@media screen and (max-width: 365px) {
		font-size: 8px !important;
	}
}