// 
// user-variables.scss
// Use this to overwrite Bootstrap and Landkit variables
//

//Fonts
$font-family-base: 'Lato', sans-serif;
$title-font: 'futura-pt', 'Rubik', 'Lato', sans-serif;

$h1-font-size: 40px;
$h1-font-family: $title-font;

$h2-font-size: 29px;
$h2-font-family: $title-font;

$h3-font-size: 21px;
$h3-font-family: $font-family-base;
$h3-line-height: 26px;

$p1-font-family: $font-family-base;
$p1-font-size: 21px;
$p1-line-height: 30px;

$p2-font-family: $font-family-base;
$p2-font-size: 16px;
$p2-line-height: 22px;


$navbar-nav-link-font-weight: 500;
//$navbar-nav-link-font-color: white;
//$dropdown-bg: black;

//Buttons
$rounded-pill: 2rem;

// # Colors
// ## By Name
$white: #FFF;
$black: #000;
$dblue: #323d53;
$brown: #b86d62;
$peach:	#d57a66;
$orange: #d4825b;
$purple: #7e4d64;
$blush: #ac627d;
$pink: #d47486;
$lavender: #766d88;
$pale: #dbdac8;
$black: #1E1E1E;

// ## Primary & etc
$primary: $dblue;
$primary-desat: $lavender;
$success: $blush;
$info: $brown;
$warning: $peach;
$danger: $orange;

//body colors - commented out because htis works with dark mode
//$body-bg: #000;
//$body-color: $white;

//Gradients
$std-gradient: linear-gradient(to bottom right, rgb(126, 77, 100) 20%, rgb(236, 136, 114) 80%);
$geo-gradient: linear-gradient(to bottom left, #7E4D64 0%, #AD616D 30%, #D77C6C 50%, #F39F67 90%, #FFC964 100%);
$tbl-gradient: radial-gradient(#cccac7,#c5c2be);

@mixin for-mobile-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}