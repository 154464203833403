//@import 'user-variables' must be before this in scss files that include this

.wide-container {
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	margin-right: auto;
	margin-left: auto;
	max-width: 1440px;
}
.extra-container {
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	margin-right: auto;
	margin-left: auto;
	max-width: 1080px;
}
.standard-container {
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	margin-right: auto;
	margin-left: auto;
	max-width: 940px;
}
.narrow-container {
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	margin-right: auto;
	margin-left: auto;
	max-width: 640px;
}
