.blog {
	.posts.container {
		max-width: 1100px;
		display: flex;
		p {
			flex: 100%;
		}
		align-items: center;
	}
	.all-posts {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		max-width: 100%;
	}

	.post {
		flex: none;
		width: 335px;
		padding-top: 20px;
		padding-bottom: 14px;
		margin-bottom: 45px;
		color: black;
	}

	.post-header {
		font-family: "Lato";
		font-size: 19px;
		line-height: 26px;
		font-weight: bold;
		color: black;
		margin-bottom: 14px;
		display: inline-block;
		color: black;
		margin-top: 15px;
		min-height: 78px;

		&:hover {
			text-decoration: underline;
		}
	}

	.post-description {
		font-size: 14px;
		line-height: 22px;
	}

	.post-logo-container {
		height: 200px;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.post-detail-content img {
		margin: 20px 0;
	}

	.post-detail-author small {
		margin-right: 10px;
	}

	.content {
		margin-bottom: 80px;
	}
}

.blog-detail .content h1 {
	margin-top: 20px;
}

.blog-detail .post-detail-author {
	small + small {
		margin-left: 20px;
	}
}

.blog .posts-page {
	margin-top: 40px;
}

.blog .posts.container.post-page {

	img {
		max-width: 100%;
		max-height: 100%;
		margin: 10px;
	}

	.all-posts {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		max-width: 100%;
	}

	.post {
		flex: none;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 14px;
		margin-bottom: 45px;
		color: black;
	}

	.post-detail-author {
		margin-bottom: 20px;
	}

}