.dark-nav {
	background: $dblue;
}
.top-nav {
	width: 100%;
	height: 108px;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	justify-content: space-between;
	padding: 20px;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
}
.menu {
	display: flex;
	flex-direction: row;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.menu > li {
	margin: 0 1rem;
	overflow: hidden;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
	display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
	display: block;
	background-color: black;
	position: absolute;
	height: 4px;
	width: 30px;
	transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
	border-radius: 2px;
}

.menu-button::before {
	content: '';
	margin-top: -8px;
}

.menu-button::after {
	content: '';
	margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
	margin-top: 0px;
	transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
	background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
	margin-top: 0px;
	transform: rotate(-405deg);
}

@media (max-width: 992px) {
	.menu-button-container {
		display: flex;
	}
	.menu {
		position: absolute;
		top: 0;
		margin-top: 108px;
		left: 0;
		flex-direction: column;
		width: 100%;
	}
	#menu-toggle ~ .menu li {
		height: 0;
		margin: 0;
		padding: 0;
		border: 0;
		transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
	}
	#menu-toggle:checked ~ .menu li {
		border-top: 1px solid rgb(241,244,248);
		padding-top: 8px;
		padding-left: 24px;
		padding-right: 24px;
		padding-bottom: 46px;
		transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
		background: white;
		z-index: 100;
	}
	.menu > li {
		width: 100%;
	}
}
.menu-link:after{
	display: block;
	content: '';
	line-height: 100%;
	transform: scaleX(0);
	transition: transform 250ms ease-in-out;
	transform-origin: 100% 50%;
}
.menu-link:hover:after,
.menu-link:focus:after {
	transform: scaleX(1);
	transform-origin: 0% 50%;
}
.menu-link:after{
	border-bottom: solid 3px $peach;
}
.menu-item .menu-link,
.menu-item .main-button{
	color: black;
	font-size: 17px;
	font-family: $title-font;
	display: block;
}
.menu-link{
	padding-top: 8px;
	padding-bottom: 8px;
	text-decoration: none !important;
}
.dark {
	.menu-item .menu-link,
	.menu-item .main-button{
		color: white;
	}
	.menu-button,
	.menu-button::before,
	.menu-button::after {
		display: block;
		background-color: white;
	}
	#menu-toggle:checked ~ .menu li {
		background: $dblue;
	}
}
.active:after {
	transform: scaleX(1) !important;
}
.top-nav.dark {
	background-color: $dblue;
}
.button{
	color: black;
}

