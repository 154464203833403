@import 'user-variables';


.login-section{
	background: linear-gradient(to bottom left, #7E4D64 0%, #AD616D 30%, #D77C6C 50%, #F39F67 90%, #FFC964 100%);
	width: 100%;
}
.login-container{
	padding-top: 200px;
	padding-bottom: 500px;
}
.login-box{
	background-color: $dblue;
	width: 480px;
	border-radius: 8px;
	padding-left: 28px;
	padding-right: 28px;
	padding-top: 50px;
	padding-bottom: 130px;
	margin: auto;

	.studies-description{
		margin-top: 0;
		font-size:18px;
		color: #CDCDCD;
	}

	h2 {
		font-family: $title-font;
		font-size: $h1-font-size;
		color: $white;
	}
	p {
		margin-top: 24px;
		color: $white;
		padding-left: 0;
		font-family: $font-family-base;
		font-size: $p1-font-size;
	}
	.white{
		color: $white;
	}
	.orange{
		color: $orange;
	}
	input{
		margin-top: 20px;
		border-radius: 0;
	}
}
.signin-button{
	margin-top:28px;
	width: 100%;
	border: 0;
	padding:16px;
	font-size: $p1-font-size;
	font-weight: 800;
}