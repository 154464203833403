/**
 * Custom variables followed by theme variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */

// Bootstrap functions
@import '../assets/node_modules/bootstrap/scss/functions';

// Theme functions
@import 'theme/functions';

// Custom variables
@import 'user-variables';

// Theme variables
@import 'theme/variables';

// Bootstrap core
@import '../assets/node_modules/bootstrap/scss/bootstrap';

// Theme core
@import 'theme/theme';

// Syntax highlighting
@import 'syntax';

// Custom core
@import 'user';

// Page specific custom pages
@import 'content';

// Hamburger collapse;
@import 'hamburger';

// syntax highlighting
@import 'syntax';

@import 'projects';

@import 'contact';

@import 'studies-login';

@import 'blog';

@import 'standard-layout';

@import 'partners';