.partners {
	.header-description {
		padding-left: 40px;
	}
	@include media-breakpoint-up(lg){
		.header-description {
			padding-right: 80px;
		}
	}
	.partners.container {
		max-width: 1100px;
		display: flex;
		p {
			flex: 100%;
		}
		align-items: center;
	}
	.all-partners {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		max-width: 100%;
	}

	.partner {
		flex: none;
		width: 335px;
		padding-top: 20px;
		padding-bottom: 14px;
		margin-bottom: 45px;
		color: black;
	}

	.partner-header {
		font-family: "Lato";
		font-size: 19px;
		line-height: 26px;
		font-weight: bold;
		color: black;
		margin-bottom: 14px;
		display: inline-block;
		color: black;
		margin-top: 15px;
	}
	.desc-why {
		min-height: 226px;
	}
	.partner-description {
		font-size: 14px;
		line-height: 22px;
	}

	.partner-logo-container {
		width: 100%;
		height: 125px;
		background-color: #f0f5f9;
		padding: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
	
		img {
			max-height: 100%;
			max-width: 100%;
		}
	
		@include media-breakpoint-down(sm) {
			height: auto;
		}
	}

	.partner-detail-content img {
		margin: 20px 0;
	}

	.partner-detail-author small {
		margin-right: 10px;
	}
	.quote-text {
		font-size: 14px;
	}
	.quote-cite {
		font-size: 12px;
		> cite {
			padding-left: 5px;
		}
		> img {
			float: right;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			margin-right: 5px;
		}
	}
	.content {
		margin-bottom: 20px;
	}
	blockquote {
		border: none;
	}
}