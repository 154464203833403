
body{
	color: black;
	background-color: white;
}
.phone1 {
	font-family: $title-font;
	font-size: 17px;
}
.under-input{
	border: 1px solid $peach;
	background-color: white;
	color: black;
}
.send{
	background-color: white;
	color: black;
	border: 2px solid $peach;
}
.send:hover{
	background-color: $peach;
	color: white;
}

.navbar-brand {
	width: 191px;
}
.navbar-brand.dark {
	width: 195px;
}
$navbar-nav-link-padding-x: 20px;


//Background Colors
.bg-dblue{ background-color:  $dblue;}
.bg-brown{ background-color:  $brown;}
.bg-peach{ background-color:  $peach;}
.bg-orange{ background-color:  $orange;}
.bg-purple{ background-color:  $purple;}
.bg-blush{ background-color:  $blush;}
.bg-pink{ background-color:  $pink;}
.bg-lavender{ background-color:  $lavender;}
.bg-pale{ background-color: $pale;}
.bg-gpeach {background-color: #FCFAFB;}

//TextColors
.text-dblue{ color:  $dblue;}
.text-brown{ color:  $brown;}
.text-peach{ color:  $peach;}
.text-orange{ color:  $orange;}
.text-purple{ color:  $purple;}
.text-blush{ color:  $blush;}
.text-pink{ color:  $pink;}
.text-lavender{ color:  $lavender;}
.text-pale{ color: $pale;}

.main-button {
	color: white !important;
	padding-left: 32px;
	padding-right: 32px;
	padding-top: 8px;
	padding-bottom: 8px;
	background: $std-gradient;
	display: inline-block;
	border: 0;
}
.main-button.active{
	text-decoration: underline;
}

.hero {
	background-image: url('/assets/img/art/colored-waves-2.svg');
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: -45px;
	padding-top: 95px;

	padding-bottom: 95px;
}
@include media-breakpoint-up(md) {
	.hero {
		background-size: 100% 100%;
	}
}
//text
.big2 {
	/* font-family: futura-pt, itc-avant-garde-gothic-pro, rubik, sans-serif; */
	font-family: $title-font;
	font-weight: 500;
	font-size: 12vw;
	text-transform: uppercase;
	line-height: 90%;
	letter-spacing: -0.025em;
	color: #142131;
}
@include media-breakpoint-up(sm) {
	.big2 {
		font-size: 8vw;
	}
}
@include media-breakpoint-up(xl) {
	.big2 {
		font-size: 5vw;
	}
}
.contact-options {
	.sub {
		font-size: 20px;
	}
}
.contact-markdown {
	margin-bottom: 50px;
}
.gradient {
	background: $std-gradient;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.navbar {
	font-family: $title-font;
}
.navbar-brand {
	height: 68px;
	background-size: cover;
}
.eight-weeks {
	font-size: 24px;
	height: 192px;
}
@include media-breakpoint-up(sm) {
	.eight-weeks{
		height: 144px;
	}
}
@include media-breakpoint-up(md) {
	.eight-weeks{
		height: initial;
	}
}
.typed{
	white-space: nowrap;
}
.flex-container{
	display: flex;
	flex-direction: row;
 	justify-content: space-around;
 	flex-wrap: wrap;
}
.statement-container{
	margin-top: 80px;
}
.tech-container{
	margin-top: 50px;
}
.pre-contact-form-CTA {
	margin-top: 25px !important;
}
.front-list {
	margin-bottom:10px;
	font-size: 19px;
	line-height: 30px;
}
@include media-breakpoint-up(sm) {
	.front-list{

	}
}
@include media-breakpoint-up(md) {
	.pre-contact-form-CTA{
		font-size: 40px !important; 
		margin-top: 0px !important;
	}
	.front-list{

	}
}
@include media-breakpoint-up(lg) {
	.front-list{

	}
}
.front-ul { 
	list-style: square inside url("../../assets/img/icon/prompt.svg"); 
	padding-left:0;
}
.content .subhead{
	margin-top: 80px;
}
.front-social-proof {
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding-left: 10px;
	padding-right: 10px;
	margin-top: 70px;
}
.front-social-proof-card {
	display: block;
	padding-top: 30px;
	padding-bottom: 30px;
	box-shadow: 0 3px 10px rgb(133, 133, 133);
}
.front-social-proof-image {
	width: 100%;
}
@include media-breakpoint-up(sm) {
	.front-social-proof{
		width: 75%
	}
}
@include media-breakpoint-up(md) {
	.front-social-proof{
		width: 50%
	}
}
.footer {
	padding-top: 15px;
	border-top: 1px solid #f1f4f8;
	clear:both;
}
.under-input{
	-webkit-appearance: textfield;
	cursor: text;
	outline: none;
	padding-left: 10px;
	margin-bottom: 10px;
	width: 100%;
}
.input-text {
	margin-top: 8px;
}
.send{
	display:block;
	outline: none;
	margin-left:auto;
	transition: .5s;
}
.right-button{
	display: block;
	margin-left: auto;
}
@include media-breakpoint-up(md) {
	.under-input{
		width: 55%;
	}
	.input-text{
		font-size:24px;
	}
	.tl-md{
		text-align:left !important;
	}
	.tr-md{
		text-align:right !important;
	}
	.tc-md{
		text-align:center !important;
	}
}

.nodisplay{
	display: none;
}

// animation
#call-back{
	width: 300px;
	position: top;
	display: inline-block;
	margin-top: -23px;
}
@include media-breakpoint-up(lg)
{
	#call-back{

	width: 500px;
}
}
@include media-breakpoint-down(sm) {
	#call-back{
		display: none;
	}
}
#number-phone{
	vertical-align: top;
	width: 225px;
	//display: inline-block;
}
.heroSVG{
	width: 300px;
	height: 240px;
}
.tech-list {
	transition: padding .2s, background-color .2s;
	background-color: white;
	border-radius: 5px;
	padding: 8px;
}
.tech-list:hover {
	padding: 8px;
	background-color: #D9E2EF;
}
.tech-icon-front {
	margin-left: auto;
	margin-right: auto;
	height: 85px;
	display: block;
}
.tech-text-front {
	text-align: center;
}
.typeform-widget{
	margin-top: 75px;
	height:800px;
	box-shadow: 4px 4px 10px 0px rgba(0,0,0,0.2);
}
.marquee{
    overflow:hidden;
    position:relative;
}
.marquee-fade-left{
    background:linear-gradient(to right,white 0,rgba(255,255,255,0) 100%);
    height:100%;
    left:0;
    position:absolute;
    top:0;
    width:20%;
    z-index:2;
}
.marquee-fade-right{
    background:linear-gradient(to left,white 0,rgba(255,255,255,0) 100%);
    height:100%;
    right:0;
    position:absolute;
    top:0;
    width:13%;
    z-index:3;
}
.marquee-container{
    display:flex;
    flex-wrap:nowrap;
    width:3800px;
    color:#555;
    margin-top: 30px;
}
.tools-div{
    animation:marquee 90s linear infinite;
    display:flex;
    flex-wrap:nowrap;
}
.marquee{
	margin-top: 45px;
}
.marquee-tool{
	display: inline-block;
	margin-left: 100px;
	height: 44px;
	width: auto;
	opacity: .5;
	border-radius: 5px;
}
.marquee-tool:hover{
	opacity: 1;
	}
@keyframes marquee{
    0%{
        transform:translate3d(0,0,0);
    }
    100%{
        transform:translate3d(-100%,0,0);
    }
}
.marquee-pause{
	animation-play-state: paused;
}
.articles.featured {
	margin-top: 80px;
	> h1 {
		padding-left: 20px;
	}
}
.articles.standard {
	> h2 {
		padding-left: 20px;
	}
}
.partners.featured {
	margin-top: 80px;
	> h1 {
		padding-left: 20px;
	}
}
.partners.standard {
	> h2 {
		padding-left: 20px;
	}
}

/* new VRE 20220929 */
#skip-to-content {
	background: white;
	color: #7e4d64;
	font-weight: bold;
	padding-left: 1rem;
	padding-right: 1rem;
	position: absolute;
	transform: translateY(-100%);
	text-decoration: none;
	border: 1px dashed #d57a66;
	border: 0.1rem dashed #d57a66;
	border-left: none;
	border-top: none;
	top: 0;
	left: 0;
	z-index: 9999;
}
#skip-to-content:focus {
	transform: translateY(0%);
	outline: none;
}