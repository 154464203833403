//light and darkmode handlers

.content{
	color: #303030;
}
h4{
	color: #888;
}

//Author-box
.author-box {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	> span {
		white-space: nowrap;
	}
	.publish-date {
		color:rgb(117,117,117);
	}
	margin-bottom: 32px
}
.author {
	flex-grow: 1;
	display: flex;
	flex-wrap: nowrap;
	.post-headshot-image {
		margin-left: 0px;
		margin-right: 15px;
		border-radius: 50%;
		height: 54px;
		width: 54px;
	}
}
.social {
	display: flex;
	flex-wrap: nowrap;
	.social-link img, svg {
		height: 24px;
		width: 24px;
		border-radius: 0px;
		margin-left: 10px;
		margin-right: 0px;
		margin-bottom: 13px;
		display: inline;
		fill:rgb(117,117,117);
	}
	.social-link:hover svg {
		fill: rgb(0,0,0);
	}
}


//basics
.content{
	.container{
		max-width: 740px;
	}
	/* font-family: 'Lato', Sans-Serif; */
	.window{
		padding-top: 25px;
	}


//images
	img,
	canvas,
	iframe,
	video,
	svg,
	select,
	textarea {
	  max-width: 100%;
	}
	img {
		border-radius: 5px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	.small{
		width: 30%;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	.aside{
		background-color: #F1F1EF;
		border-radius: 4px;
		font-weight: 800;
		padding-right: 25px;
		padding-left: 25px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.rightsmall{
		display: none;
	}
	@include media-breakpoint-up(lg) {
		.left{
			display: initial;
			float: left;
			display: block;
			margin-left: -150px;
			margin-right: 32px;
			margin-top: 40px;
			width: 525px;
		}
		.leftsmall{
			display: initial;
			float: left;
			display: block;
			margin-left: -150px;
			margin-right: 32px;
			margin-top: 40px;
			width: 225px;			
		}
		.right{
			display: initial;
			float: right;
			margin-right: -150px;
			margin-left: 32px;
			margin-top: 40px;
			margin-bottom: 16px;
			width: 525px;
		}
		.rightsmall{
			display: initial;
			float: right;
			margin-right: -150px;
			margin-left: 32px;
			margin-top: 40px;
			margin-bottom: 16px;
			width: 225px;
		}
	}

//Headers
	h1 {
		/* font-family: futura-pt, late, Sans-Serif; */
		font-family: $title-font; /* unnecessary anyway? */
		font-size: 40px;
		font-weight: 500;
		clear: both;
		letter-spacing: -0.5px;
		line-height: 40px;
		margin-top: 56px;
	}
	h2 {
		/* font-family: futura-pt, Sans-Serif; */
		font-family: $title-font; /* unnecessary anyway? */
		font-size: 26px;
		font-weight: 500;
		margin-bottom: 0px;
		clear: both;
		letter-spacing: -0.3px;
		margin-top: 30px;
	}
	h3 {
		/* font-family: futura-pt, Sans-Serif; */
		font-family: $title-font; /* unnecessary anyway? */
		font-size: 24px;
		font-weight: 900;
		margin-top: 20px;
		margin-bottom: 0px;
		clear: both;
	}
//Note: h4 is for subtitles;
	h4 {
		/* font-family: Arial, 'Helvetica Neue', 'Lato', Sans-Serif; */
		font-size: 20px;
		font-weight: 600;
		margin-top: -16px;
	}


//Paragraph
	p {
		font-size: 19px;
		line-height: 30px;
		margin-top: 10px;
		code{
			font-size: 15px;
			padding-top: 2px;
			padding-bottom: 2px;
			background-color: #f6f6f6;
			border: 1px solid #dadada;
		}
	}
	strong {
		font-weight: 900;
	}
	.name{
		margin-top: 25px;
		margin-bottom: -10px;
	}
	.caption{
		color: rgba(55, 53, 47, 0.65);
		font-size:14px;
		line-height:19.6px;
	}


//Lists
	ul,ol {
		font-size: 19px;
		margin-left: 25px;
		line-height: 30px;
	}
	li {

	}
	li>p {
		padding: 0;
		margin: 0;
		line-height: 24px;
	}
	ul {
		list-style: disc;
	}
	ul ul{
		list-style: circle;
		margin-left: 0;
	}
	ul ul ul{
		list-style: square;
		margin-left: 0;
	}

//Links
	a{
		color: $orange;
	}
	a:visited {
	  	color: $orange;
	}

	a:hover,
	a:focus,
	a:active {
	  	color: $lavender;
	}

//Blockquotes
	blockquote {
	  margin: 0;
	  border-left: 5px solid #7a7a7a;
	  font-style: italic;
	  padding-top: 6px;
	  padding-left: 15px;
	  padding-right: 15px;
	  padding-bottom: 6px;
	  text-align: left;
	}

//Responsive details
	@include media-breakpoint-up(md) {
		p {
			padding-left: 0px;
			padding-right: 0px;
		}
		pre {
			margin-left: 0px;
			margin-right: 0px;
		}
	}
//Tables
	table {
		border-radius: 5px;
		width: 100%;
		margin-top: 10px;
		margin-bottom: 20px;
	}
	th{
		background-color: $peach;
		color: white;
		text-align: center !important;
		font-weight: 700;
		/* font-family: 'Oswald', sans-serif; */
		font-size: 16px;
	}
	tr{
		border-bottom: 1px solid #f1f4f8;
		font-size: 14px;
		line-height: 26px;
		/* font-family: 'Lato', sans-serif; */ /* unnecessary, right? */
	}
	tr:hover{
		background-color: $pale;
	}
}